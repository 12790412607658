<template>
  <div class="mitarbeiter">
    <v-row>
      <v-col cols="12" sm="12" offset-sm="0">
        <v-card id="mitarbeiterlistcard" :loading="loading">
          <v-list two-line>
            <h2 v-if="!!!mitarbeiterList">Keine Mitarbeiter gefunden</h2>
            <v-list
              v-else
              v-for="(item, index) in mitarbeiterList"
              :key="index"
            >
              <Mitarbeiter
                :mitarbeiter="item"
                @delete="askDeleteMitarbeiter(index)"
                :showActions="true"
              />
              <v-divider class="mx-4" vertical></v-divider>
            </v-list>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dlgDelete" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Mitarbeiter löschen?</v-card-title>
        <v-card-text>
          Soll der Mitarbeiter
          <strong>{{ deleteMitarbeiterName }}</strong> wirklich gelöscht werden?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" outlined text @click="dlgDelete = false"
            >Abbrechen</v-btn
          >
          <v-btn dark color="red darken-1" @click="deleteMitarbeiter">
            <v-icon>mdi-delete</v-icon>Löschen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import MitarbeiterService from "@/services/MitarbeiterService.js";
import Mitarbeiter from "@/components/Mitarbeiter.vue";

export default {
  name: "MitarbeiterList",
  data: () => ({
    loading: false,
    dlgDelete: false,
    deleteItem: false,
    deleteMitarbeiterName: ""
  }),
  components: {
    Mitarbeiter
  },
  methods: {
    askDeleteMitarbeiter(index) {
      this.deleteMitarbeiterName = this.getNameByIndex(index);
      this.dlgDelete = true;
      this.deleteItem = index;
    },
    deleteMitarbeiter() {
      this.dlgDelete = false;
      MitarbeiterService.delete(this.mitarbeiterList[this.deleteItem]._id).then(
        () => {
          this.mitarbeiterList.splice(this.deleteItem, 1);
          this.$notify({
            group: "dataline",
            type: "info",
            title: "Mitarbeiter gelöscht",
            text:
              "Mitarbeiter " + this.deleteMitarbeiterName + " wurde gelöscht"
          });
          this.deleteMitarbeiterName = "";
        }
      );
    },
    getNameByIndex(index) {
      if (this.mitarbeiterList == null) {
        return "";
      } else
        return (
          this.mitarbeiterList[index].firstname +
          " " +
          this.mitarbeiterList[index].lastname
        );
    }
  },

  computed: {
    mitarbeiterList() {
      return this.$store.getters["mitarbeiter/getMitarbeiterList"];
    }
  },

  created() {
    this.loading = true;
    this.$store
      .dispatch("mitarbeiter/fetchMitarbeiterList")
      .then(() => {
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        if (error.response.status != 401) {
          this.$notify({
            group: "dataline",
            type: "error",
            title: "Fehler",
            text: error.message
          });
        }
      });
  }
};
</script>

<style scoped>
#mitarbeiterlistcard {
  margin: 20px;
}
</style>
