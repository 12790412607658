<template>
  <div>
    <v-list-item class="mitarbeiter">
      <v-list-item-icon>
        <v-avatar size="48" color="deep-purple">
          <img v-if="mitarbeiter.image" :src="mitarbeiter.image" />
          {{ getInitials }}
        </v-avatar>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title v-html="getName"></v-list-item-title>
        <v-list-item-subtitle v-if="!!mitarbeiter.street" v-html="getAdresse"></v-list-item-subtitle>
        <span v-if="!!!showActions" :class="getLastStatus">
          {{ getLastStatus }}
          <span v-if="!!getStatusTime">@ {{ getStatusTime }}</span>
        </span>
      </v-list-item-content>

      <div v-if="!!showActions" class="d-flex justify-end">
        <v-btn
          :to="{
            name: 'mitarbeiter-edit',
            params: { mitarbeiter: this.mitarbeiter }
          }"
          color="primary"
          class="mr-5"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>

        <v-btn
          :to="{
            name: 'mitarbeiter-times',
            params: { id: this.mitarbeiter._id }
          }"
          color="secondary"
          class="mr-5"
        >
          <v-icon>mdi-timetable</v-icon>
        </v-btn>

        <v-btn color="error" @click="deleteItem">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </div>
    </v-list-item>
  </div>
</template>

<script>
export default {
  name: "Mitarbeiter",
  data: () => ({}),
  props: ["mitarbeiter", "showActions"],
  methods: {
    deleteItem() {
      this.$emit("delete");
    },
    isCurrentDate(lastTimestamp) {
      let currentDate = new Date();
      let lastDate = new Date(lastTimestamp);

      return (
        currentDate.getFullYear() === lastDate.getFullYear() &&
        currentDate.getDate() === lastDate.getDate() &&
        currentDate.getMonth() === lastDate.getMonth()
      );
    }
  },
  computed: {
    getInitials() {
      return (
        this.mitarbeiter.firstname.substring(0, 1).toUpperCase() +
        this.mitarbeiter.lastname.substring(0, 1).toUpperCase()
      );
    },
    getName() {
      return this.mitarbeiter.firstname + " " + this.mitarbeiter.lastname;
    },
    getLastStatus() {
      let status = this.mitarbeiter.lastStatus;

      if (!status.timestamp) {
        return "Noch nicht eingecheckt";
      }
      return status.status;
    },
    getStatusTime() {
      let lastStatus = this.mitarbeiter.lastStatus;
      if (!lastStatus || !lastStatus.timestamp) {
        return false;
      }
      let zeit = new Date(lastStatus.timestamp);
      //return zeit.toLocaleDateString("de-DE");
      if (this.isCurrentDate(zeit)) {
        let uhrzeit;
        if (zeit.getHours() < 10) {
          uhrzeit = "0" + zeit.getHours();
        } else {
          uhrzeit = zeit.getHours();
        }

        if (zeit.getMinutes() < 10) {
          uhrzeit += ":0" + zeit.getMinutes();
        } else {
          uhrzeit += ":" + zeit.getMinutes();
        }
        return uhrzeit;
      } else {
        return null;
      }
    },

    getAdresse() {
      return (
        this.mitarbeiter.street +
        " " +
        this.mitarbeiter.streetnr +
        "<br/>" +
        this.mitarbeiter.plz +
        " " +
        this.mitarbeiter.city
      );
    }
  }
};
</script>

<style scoped>
.mitarbeiter:hover {
  background-color: #cbdfff;
}
.checkin {
  font-weight: bold;
  font-size: 1.2em;
  color: green;
}
.checkout {
  font-weight: bold;
  font-size: 1.2em;
  color: red;
}
</style>
