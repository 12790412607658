<template>
  <div>
    <v-container>
      <v-col cols="12" sm="12" offset-sm="0">
        <v-card>
          <v-container>
            <v-row align="center">
              <v-col cols="12" xs="12" sm="8">
                <v-card-title>Mitarbeiterverwaltung</v-card-title>
              </v-col>
              <v-col cols="12" xs="12" sm="4" style="text-align: right;">
                <v-btn to="/mitarbeiter/edit" color="success">
                  <v-icon style="margin-right: 10px;">mdi-account-plus</v-icon>
                  <span>Neuer Mitarbeiter</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>

          <MitarbeiterList />
        </v-card>
      </v-col>
    </v-container>
  </div>
</template>

<script>
import MitarbeiterList from "@/components/MitarbeiterList.vue";

export default {
  name: "MitarbeiterMgmt",
  components: { MitarbeiterList }
};
</script>

<style scoped></style>
